
h1 {
    font-weight: 300 !important;
    color: rgb(51, 51, 51);
    font-size: 44px;
}

button.back-to-index {
    color: #fff;
    font-size: 16px;
}

button.back-to-index:hover {
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
}

button.back-to-index:focus {
    outline: 1px dotted #000;
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
}

button.find-out-more {
    background-color: white !important;
    color: #333 !important;
    border: 1px solid black;
}

button.find-out-more:hover {
    background-color: rgb(243, 243, 243) !important;
    border-color: rgb(51, 51, 51) !important;
}

button.find-out-more:focus {
    outline: 1px dotted #000;
    background-color: rgb(243, 243, 243) !important;
    border-color: rgb(51, 51, 51) !important;
}

.together {
    color: rgb(51, 51, 51);
    background-color: white;
    text-align: left;
    transition: border 0.1s ease-out 0s;
    font-weight: 300;
    letter-spacing: normal;
    box-sizing: border-box !important;
    font-family: "UniversNext", Arial, sans-serif !important;
    font-size: 14px;
}

.title-div .text {
    color: rgb(51, 51, 51);
    text-align: left;
    transition: border 0.1s ease-out 0s;
    font-weight: 300;
    letter-spacing: normal;
    box-sizing: border-box !important;
    font-family: "UniversNext", Arial, sans-serif !important;
    font-size: 14px;
}

.t-success {
    left: 33%;
    position: absolute;
    width: 700px;
    height: 300px;
}

.t-success img {
    height: 190px;
}

.t-success h2 {
    font-size: 36px
}

.t-success .t-icon {
    background-image: url(../../assets/LOGO.svg) !important;
    background-position: 50%;
    background-repeat: no-repeat;
    color: #000;
    display: inline-block;
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
    width: 100px !important
}

.t-success .hsbc {
    left: 159px;
    position: absolute;
    top: 121px;
    display: inline-block;
    font-weight: bold;
}

.t-success .text {
    width: 490px;
    font-size: 18px;
    line-height: 30px;
}

.t-success button {
    background-color: #d20601;
    color: #fff;
    margin-top: 30px
}

.t-success .tradeshift-cash {
    margin: 40px 0 0 0;
    padding: 0;
}


.t-success .t-call {
    margin: 0 0 24px 0;
    padding: 0;
}

.ttr-btn {
    border: none;
    border-radius: 0;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.ttr-btn:focus {
    box-shadow: none
}

.ttr-btn:disabled {
    opacity: 1
}

.ttr-btn.btn-disabled,
.ttr-btn[disabled] {
    background-color: #9f9f9f;
    color: #fff
}