body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    line-height: 1;
    background-color: #f3f3f3;
    color: #333;
    font-family: "UniversNext", Arial, sans-serif;
    font-weight: 400;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden !important;
    overflow: visible;
}

.error-header {
    background-color: #fff !important;
}


.error-container {
    margin: auto;
    width: 1277px;
}

.error-content .title {
    font-size: 2.75rem;
    line-height: 3.125rem;
    margin-bottom: 1.5rem;
}

.error-content {
    padding: 77px 219px 107px 279px;
}

.error-404 {
    font-size: 14px;
    font-weight: 700;
}

.detail {
    margin: 16px 0 40px 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
}

.ttr-notfound {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ttr-btn {
    display: block;
    background-color: #db0011;
    color: rgb(255, 255, 255);
    margin-top: 10px;
    font-weight: 400;
}

button {
    font-size: 16px !important;
}

.ttr-btn {
    border: none;
    border-radius: 0;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

button.error-btn:hover {
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
}

button.error-btn:focus {
    outline: 1px dotted #000;
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
}

.ttr-btn:focus {
    box-shadow: none
}

.ttr-btn:disabled {
    opacity: 1
}

.ttr-btn.btn-disabled,
.ttr-btn[disabled] {
    background-color: #9f9f9f;
    color: #fff
}