.ttr-submit-container {
    background: #f3f3f3;
    width: 100%;
    padding: 32px 32px 0px 32px;
}

#phoneNumberIcon {
    left: 70px;
    top: 15px;
}

.checkbox {
    line-height: 40px;
    vertical-align: sub;
    text-align: center;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    cursor: pointer;
}

input.error-input {
    border-color: #a8000b !important;
    background-color: #f9f2f3 !important;
}

select.error-input {
    border-color: #a8000b !important;
    background-color: #f9f2f3 !important;
}


.loading {
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    height: 105vw;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(19, 17, 17, 0.3);
    background-image: url('../../assets/loading.gif');
    background-size: 30px 30px;
}

label {
    display: block;
}

select {
    padding: 0 9px;
    line-height: 38px;
    color: #333;
    font-size: 14px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    cursor: pointer;
}

option {
    cursor: pointer;
}

select#titleInput {
    background: url(../../assets/LowerTriangle.svg) no-repeat 355px;
}

select#phoneNumberInput {
    background: url(../../assets/LowerTriangle.svg) no-repeat 73px;
}

select:focus {
    border-color: rgb(34, 120, 206);
    outline: thin dotted;
    background-color: rgb(255, 255, 255);
}

select:hover {
    border-color: rgb(34, 120, 206);
    background-color: rgb(255, 255, 255);
}

input:hover {
    border-color: rgb(34, 120, 206) !important;
    background-color: rgb(255, 255, 255) !important;
}

input:not([type="checkbox"]):focus {
    border-color: rgb(34, 120, 206) !important;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    vertical-align: baseline;
    text-transform: none;
    appearance: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: thin dotted;
    background-color: rgb(255, 255, 255);
}

input[type="checkbox"]:focus {
    outline: 0.5px dotted rgb(34, 120, 206);
}

input[type="checkbox"]:hover {
    border-color: rgb(34, 120, 206);
    outline: 0.5px dotted rgb(34, 120, 206);
}

input.sub-checkbox[type="checkbox"] {
    outline: 1px solid rgb(175, 0, 13);
}

.btn-hover-focus:hover {
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
}

.btn-hover-focus:focus {
    outline: none;
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
}

.header-btn {
    background-color: white !important;
    color: #333 !important;
    border: 1px solid black;
}

.header-btn:focus {
    outline: 1px dotted #000;
    background-color: rgb(243, 243, 243) !important;
    border-color: rgb(51, 51, 51) !important;
}

.header-btn:hover {
    background-color: rgb(243, 243, 243) !important;
    border-color: rgb(51, 51, 51) !important;
}

.submit-btn-disabled {
    cursor: default !important;
    background-color: #9f9f9f !important;
    color: #757171 !important;
    border-color: #9f9f9f !important;
}


.submit-button-active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "UniversNext", Arial, sans-serif !important;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    text-transform: none;
    appearance: none;
    box-shadow: none;
    text-decoration: none;
    -webkit-box-align: center;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s, border 0.1s ease-out 0s;
    height: 2.5rem;
    box-sizing: border-box;
    align-items: center;
    display: flex !important;
    margin-bottom: 48px;
    font-weight: 400;
    border: none;
    border-radius: 0;
    line-height: 1;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #db0011;
    color: #fff;
    margin-top: 40px;
    font-size: 16px !important;
}

.submit-btn {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    vertical-align: baseline;
    text-transform: none;
    padding: 0px 1.1875rem;
    appearance: none;
    box-shadow: none;
    text-decoration: none;
    -webkit-box-align: center;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    border-radius: 0px;
    transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s, border 0.1s ease-out 0s;
    background-color: rgb(219, 0, 17);
    border-color: rgb(219, 0, 17);
    color: rgb(255, 255, 255);
    height: 2.5rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "UniversNext", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex !important;
    margin-top: 20px;
    margin-bottom: 48px;
}

select {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    color: rgb(51, 51, 51);
    font-family: "UniversNext", Arial, sans-serif;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

#timeInput {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    color: rgb(51, 51, 51);
    font-family: "UniversNext", Arial, sans-serif;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.Morning .Afternoon {
    -webkit-text-size-adjust: 100%;
    text-transform: none;
    cursor: pointer;
    font-family: "UniversNext", Arial, sans-serif;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    -webkit-font-smoothing: antialiased;
    background-color: rgb(255, 255, 255);
    color: rgb(51, 51, 51);
    outline: none;
}

input:not([type="checkbox"]) {
    border-color: rgb(118, 118, 118);
    color: rgb(51, 51, 51);
    background-color: white;
    text-align: left;
    border-width: 0.0625rem;
    border-style: solid;
    transition: border 0.1s ease-out 0s;
    font-weight: 400;
    letter-spacing: normal;
    box-sizing: border-box !important;
    font-family: "UniversNext", Arial, sans-serif !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.t-checbox .text {
    width: 335px;
    color: rgb(51, 51, 51);
    background-color: white;
    text-align: left;
    transition: border 0.1s ease-out 0s;
    font-weight: 400;
    letter-spacing: normal;
    box-sizing: border-box !important;
    font-family: "UniversNext", Arial, sans-serif !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.error-text {
    color: rgb(51, 51, 51);
    background-color: white;
    text-align: left;
    transition: border 0.1s ease-out 0s;
    font-weight: 300;
    letter-spacing: normal;
    box-sizing: border-box !important;
    font-family: "UniversNext", Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
}

.Please-enter {
    color: rgb(51, 51, 51);
    background-color: white;
    text-align: left;
    transition: border 0.1s ease-out 0s;
    font-weight: 400;
    letter-spacing: normal;
    box-sizing: border-box !important;
    font-family: "UniversNext", Arial, sans-serif !important;
    font-size: 16px;
    display: block;
    width: 490px;
    line-height: 20px;
}

.submit-div {
    position: relative;
}

.btn-loading {
    position: absolute;
    bottom: 12px;
    left: 3px;
    display: inline-block;
    content: '';
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../assets/loading_btn.gif');
    background-size: contain;
}

.check-btn {
    text-decoration: underline;
}

.out-link {
    margin-right: 10px;
}

.t-checbox {
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    max-width: 100%;
    align-items: center !important;
    display: flex;
    flex-direction: row !important;
    margin-bottom: .5rem;
}

.t-checbox div {
    margin-left: 24px;
}

.last_checkbox span .check {
    -webkit-text-size-adjust: 100%;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    color: rgb(0, 132, 127);
    fill: rgb(0, 132, 127);
    width: 0.625rem;
    height: 0.625rem;
    visibility: visible;
    opacity: 1;
}

.t-checbox .last_checkbox {
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: baseline;
    cursor: pointer;
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: baseline;
    cursor: pointer;
}

.last_checkbox span:hover {
    border-color: rgb(34, 120, 206);
}

.last_checkbox span {
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-width: 0.0625rem;
    border-style: solid;
    position: relative;
    background: white;
    flex-shrink: 0;
    width: 1.875rem;
    height: 1.875rem;
    border-color: #333;
}

.t-checbox {
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    max-width: 100%;
    align-items: center !important;
    display: flex;
    flex-direction: row !important;
    margin-bottom: .5rem;
}

.t-checbox div {
    margin-left: 24px;
}

.time_checkbox>span.f-checkbox {
    width: 20px !important;
    height: 20px !important;
}

.f-checkbox>.check {
    width: 20px !important;
    height: 20px !important;
}

.label.time_checkbox span .check {
    -webkit-text-size-adjust: 100%;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    color: rgb(0, 132, 127);
    fill: rgb(0, 132, 127);
    width: 0.625rem;
    height: 0.625rem;
    visibility: visible;
    opacity: 1;
}

label.time_checkbox {
    margin-top: 10px !important;
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: baseline;
    cursor: pointer;
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    flex-direction: row;
    -webkit-box-align: baseline;
    align-items: baseline;
    cursor: pointer;
}

label.time_checkbox span:hover {
    border-color: rgb(34, 120, 206);
}

label.time_checkbox span {
    -webkit-text-size-adjust: 100%;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-width: 0.0625rem;
    border-style: solid;
    position: relative;
    background: white;
    flex-shrink: 0;
    width: 1.875rem;
    height: 1.875rem;
    border-color: #333;
}

li.option-item {
    display: flex;
}

.Afternoon,
.Morning {
    margin-left: 10px !important;
}


.submit-h {
    background: #fff;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px;
}

.t-submit {
    padding: 42px 104px 60px 104px;
}

.submit-back-to-top {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    margin: 0 auto;
    padding-top: 56px;
    width: 170px;
}

.submit-back-to-top button {
    border: none;
    margin: 0;
    padding: 0;
}

.submit-back-to-top .hover-circle {
    background: #000;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    height: 44px;
    width: 44px;
    position: relative;
}

.submit-back-to-top .hover-circle img {
    margin-top: 12px;
}

.submit-back-to-top .hover-circle:hover {
    background-color: #222
}

.submit-back-to-top .text {
    position: absolute;
    color: #333;
    line-height: 44px;
    margin-left: 10px;
    text-decoration: none;
    vertical-align: middle
}

.submit-back-to-top .text:hover {
    text-decoration: underline !important
}

.network-error img {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: absolute;
}

.network-error span {
    display: inline-block;
    margin-left: 25px;
}

.network-error {
    font-weight: 400;
    height: 44px;
    text-align: left;
    font-family: UniversNext, Helvetica Neue, Helvetica, Arial, sans-serif;
    line-height: 20px;
    box-sizing: border-box;
    display: block;
    width: 500px;
    padding: 10px;
    font-size: 16px;
    color: #333;
    background-color: #f9f2f3;
    border: 1px solid #fa000d;
    margin-bottom: 10px;
    margin-top: 20px;
}

/* #privacy_download_pdf:focus,
.submit-focus:focus {
    outline: 1px dotted #000 !important;
    background-color: rgb(175, 0, 13) !important;
    border-color: rgb(175, 0, 13) !important;
} */


.submit-back-to-top .hover-circle:hover {
    background-color: rgb(64, 64, 64) !important;
    border-color: rgb(64, 64, 64) !important;
}

a.last_checkbox:focus {
    outline: 1px dotted rgb(34, 120, 206) !important;
}

.back-btn:focus {
    outline: 1px dotted #000;
}

.select-left-line {
    height: 20px;
    border-left: 1px solid rgb(118, 118, 118);
    position: absolute;
    left: 344px;
    top: 12px;
    cursor: pointer;
}

.time-select-left-line {
    height: 20px;
    border-left: 1px solid rgb(118, 118, 118);
    position: absolute;
    left: 344px;
    top: 12px;
    cursor: pointer;
}

.privacyNoticeValid {
    border-color: #a8000b !important;
    background-color: #f9f2f3 !important;
}

.submit-btn-disabled {
    cursor: default !important;
    background-color: #9f9f9f !important;
    color: #757171 !important;
    border-color: #9f9f9f !important;
    outline: none;
}

.submit-back-btn {
    box-sizing: border-box;
    font-family: "UniversNext", Arial, sans-serif !important;
    border-radius: 0;
    font-size: inherit;
    line-height: inherit;
    text-transform: none;
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
}