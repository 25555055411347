body {
    margin: 0;
    padding: 0;
}

.tt-header {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    height: 70px;
    width: 100%;
    background-color: #fff !important;
}

.tt-header .header-sed {
    -webkit-text-size-adjust: 100%;
    color: #333;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    width: 100% !important;
    margin: auto;
    max-width: 76rem;
}

.tt-header .header-sed .t-icon {
    background-image: url(../../assets/LOGO.svg) !important;
    background-position: 50%;
    background-repeat: no-repeat;
    display: inline-block;
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
    width: 100px !important
}

.tt-header .header-sed span {
    font-weight: 300;
    margin-left: -60px;
    position: relative;
    top: 2px;
    font-weight: bold;
}

.tt-header .header-sed button {
    background-color: #db0011;
    color: #fff;
    display: block;
    float: right;
    height: 35px !important;
    line-height: 0px;
    margin-right: 10px;
    margin-top: 17px
}

button.success-find-out-more {
    background-color: white !important;
    color: #333 !important;
    border: 1px solid black !important;
}


button.success-find-out-more:hover {
    background-color: rgb(243, 243, 243) !important;
    border-color: rgb(51, 51, 51) !important;
}

button.success-find-out-more:focus {
    outline: 1px dotted #000;
    background-color: rgb(243, 243, 243) !important;
    border-color: rgb(51, 51, 51) !important;
}

button.success-find-out-more {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: "UniversNext", Arial, sans-serif !important;
    border-radius: 0;
    font-size: 16px;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: white !important;
    color: #333 !important;
    border: 1px solid black;
    display: block;
    float: right;
    height: 35px !important;
    line-height: 0px;
    margin-right: 10px;
    margin-top: 17px;
 }