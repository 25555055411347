.purple-bg,
.purple-bg-ul li:nth-child(2n-1) {
    background: #4126b0 !important;
    background-color: #4126b0 !important;
    color: #fff;
}

.purple-bg-ul li:nth-child(2n-1) div {
    color: #fff !important;
}


.purple-ul-li {
    background: #4126b0 !important;
    background-color: #4126b0 !important;
    color: #fff;
}

.purple-ul-li-div {
    color: #fff !important;
}


.blue-bg {
    background: #210C7D !important;
}

.purple-bg {
    background: #4126b0 !important;
}

.purple-bg-font {
    color: #210C7D !important;
}

.purple-font-white {
    color: #fff !important;
}

.ttr-btn-blue {
    box-sizing: border-box;
    font-family: "UniversNext", Arial, sans-serif !important;
    text-transform: none;
    border: none;
    border-radius: 0;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    background: #4126b0 !important;
    background-color: #4126b0 !important;
    cursor: pointer;
    color: #fff !important;
    margin-top: 40px;
}

.ttr-btn-blue:hover,
.circle-bg:hover,
.circle-bg:focus,
.ttr-btn-blue:focus {
    outline: 1px dotted #000 !important;
}

.ttr-btn-white:hover,
.ttr-btn-white:focus {
    outline: 1px dotted #fff;
}


.model-btn-white {
    font-family: "UniversNext", Arial, sans-serif !important;
    border: none;
    border-radius: 0;
    display: inline-block;
    line-height: 1;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    color: #333 !important;
    cursor: pointer;
    background-color: #fff !important;
    font-size: 16px;
    position: absolute;
    top: 16rem;
}

.ttr-btn-white {
    background-color: #fff !important;
}

.btn-purple-margin-top {
    margin-top: 40px;
}

.btn-purple-margin-bottom {
    margin-bottom: 48px;
}

.purple-at-glance {
    color: #fff;
}

.purple-how-to-btn {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: "UniversNext", Arial, sans-serif !important;
    margin: 0;
    text-transform: none;
    font-size: 16px !important;
    font-weight: 400;
    border: none;
    border-radius: 0;
    display: inline-block;
    line-height: 1;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #fff !important;
    color: #333;
    margin-top: 8px !important;
}

/*model style*/
.model-purple-than-1-5 {
    margin-left: 155px !important;
    margin-right: 0px !important;
    top: 0px !important;
}

.title-purple-img-less-1-5 {
    margin-left: 109px !important;
}

.title-purple-img-than-1-5 {
    margin-left: 155px !important;
}

.purple-top-section {
    text-align: var(--bs-body-text-align);
    font: inherit;
    line-height: 1;
    font-weight: 400;
    color: #333;
    font-size: 16px !important;
    box-sizing: border-box;
    font-family: "UniversNext", Arial, sans-serif !important;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    display: flex;
    width: 100% !important;
    margin: auto !important;
    max-width: 76rem !important;
}

.purple-first-section {
    margin-left: 109px !important;
}

.purple-model {
    margin-right: 100px !important;
}

.at-glance ul li i.purple-Check {
    background-image: url(../assets/HSBC_check_mark_blue.svg) !important;
    height: 18px;
    width: 18px !important;
    position: relative;
    top: -3px;
    margin-right: 8px;
    background-position: 50%;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle
}

.submit-circle {
    line-height: inherit;
    text-transform: none;
    box-sizing: border-box;
    font-family: UniversNext, Arial, sans-serif !important;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    font-size: 15px;
    height: 44px;
    width: 44px;
    color: #fff;
    background: #4126b0 !important;
}

.submit-circle img {
    margin-top: 12px;
}

.submit-circle:hover,
.submit-circle:focus {
    outline: 1px dotted #000 !important;
}


.purple-footer-btn {
    visibility: inherit;
    pointer-events: inherit;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: "UniversNext", Arial, sans-serif !important;
    line-height: inherit;
    margin: 0;
    text-transform: none;
    font-size: 16px !important;
    cursor: pointer;
    background-color: #4126b0 !important;
    border: none;
    border-radius: 0;
    color: #fff;
    float: right;
    height: 46px;
    text-align: center;
    width: 138px;
}

.footer-jump-btn:hover {
    outline: 1px dotted #000 !important;
}
